import React from 'react';
import { Button } from '@blueprintjs/core';

export function DefaultButton({ ...props }) {
  return <Button {...props} />;
}

export function PrimaryButton({ ...props }) {
  return <Button {...props} intent="primary" />;
}

export function DangerButton({ ...props }) {
  return <Button {...props} intent="danger" />;
}

export function WarningButton({ ...props }) {
  return <Button {...props} intent="warning" />;
}

export {
  Button,
};
