import React from 'react';
import * as PropTypes from 'prop-types';

function Page({ className = '', ...rest }) {
  return (
    <div className={`h-screen w-screen flex flex-col bg-pageBackground ${className}`} {...rest} />
  );
}

Page.propTypes = {
  className: PropTypes.string,
};

export { Page };
