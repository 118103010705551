import React from 'react';
import * as PropTypes from 'prop-types';
import { Classes } from '@blueprintjs/core';

const descriptors = {
  HeadingXXL: { tag: 'h1', classes: `text-6xl leading-10 ${Classes.HEADING}` },
  HeadingXL: { tag: 'h2', classes: `text-4xl leading-10 ${Classes.HEADING}` },
  HeadingL: { tag: 'h3', classes: `text-3xl leading-8 ${Classes.HEADING}` },
  HeadingM: { tag: 'h4', classes: `text-2xl leading-7 ${Classes.HEADING}` },
  HeadingS: { tag: 'h5', classes: `text-xl leading-6 ${Classes.HEADING}` },
  HeadingXS: { tag: 'h6', classes: `text-base leading-5 ${Classes.HEADING}` },
  BodyL: { tag: 'p', classes: 'text-lg leading-6' },
  BodyM: { tag: 'p', classes: 'text-base leading-5' },
  BodyS: { tag: 'p', classes: 'text-sm leading-4' },
  BodyXS: { tag: 'p', classes: 'text-xs leading-3' },
};

function createTypography(variant) {
  function Typography(
    {
      children, component = '',
      centered = false,
      strong = false,
      monospaced = false,
      muted = false,
      disabled = false,
      ellipsis = false,
      className = '',
    }) {
    const Tag = component || descriptors[variant].tag;
    // eslint-disable-next-line max-len
    const allClasses = ` ${descriptors[variant].classes} ${centered ? 'text-center' : ''} ${strong ? 'font-bold' : ''} ${monospaced ? Classes.MONOSPACE_TEXT : ''} ${muted ? Classes.TEXT_MUTED : ''} ${disabled ? Classes.TEXT_DISABLED : ''} ${ellipsis ? Classes.TEXT_OVERFLOW_ELLIPSIS : ''} ${className}`;
    return (
      <Tag className={allClasses}>
        {children}
      </Tag>
    );
  }

  Typography.propTypes = {
    children: PropTypes.node.isRequired,
    component: PropTypes.elementType,
    centered: PropTypes.bool,
    strong: PropTypes.bool,
    monospaced: PropTypes.bool,
    muted: PropTypes.bool,
    disabled: PropTypes.bool,
    ellipsis: PropTypes.bool,
    className: PropTypes.string,
  };

  return Typography;
}

export const HeadingXXL = createTypography('HeadingXXL');
export const HeadingXL = createTypography('HeadingXL');
export const HeadingL = createTypography('HeadingL');
export const HeadingM = createTypography('HeadingM');
export const HeadingS = createTypography('HeadingS');
export const HeadingXS = createTypography('HeadingXS');
export const BodyL = createTypography('BodyL');
export const BodyM = createTypography('BodyM');
export const BodyS = createTypography('BodyS');
export const BodyXS = createTypography('BodyXS');
